export const APP_LOCALES = [
  {
    label: 'English',
    code: 'en',
    fullCode: 'en-US',
  },
  {
    label: 'Українська',
    code: 'uk',
    fullCode: 'uk-UA',
  },
  {
    label: 'Русский',
    code: 'ru',
    fullCode: 'ru-RU',
  },
  {
    label: "O'zbek",
    code: 'uz',
    fullCode: 'uz-UZ',
  },
  {
    label: 'Монгол',
    code: 'mn',
    fullCode: 'mn-MN',
  },
] as const;
